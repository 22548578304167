<template>
  <div class="col-12">
    <b-table
      style="overflow: auto; white-space: nowrap"
      :items="bloodvanData"
      :busy="isBusy"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      responsive="sm"
    >
      <template v-slot:cell(aksi)="row">
        <b-button
          class="mx-1"
          variant="danger-2"
          @click="deleteMobilUnit(row.item.id)"
        >
          <i class="ri-delete-bin-6-line mr-1" />
          Hapus
        </b-button>
        <b-button-group>
          <router-link :to="`/dashboards/bloodvan/${row.item.id}`">
            <b-button class="mx-1" variant="success">
              <i class="ri-menu-fill mr-1" />
              Detail
            </b-button>
          </router-link>
          <router-link :to="`/dashboards/edit-bloodvan/${row.item.id}`">
            <b-button class="mx-1" variant="info-1">
              <i class="ri-edit-box-line mr-1" />
              Edit
            </b-button>
          </router-link>
        </b-button-group>
      </template>

      <template v-slot:cell(name)="row">
        <span class="ml-2">{{ row.item.name }}</span>
      </template>

      <template v-slot:cell(is_active)="row">
        <b-badge :variant="row.item.is_active === 1 ? 'success' : 'danger'">
          {{ row.item.is_active === 1 ? "Aktif" : "Non-Aktif" }}
        </b-badge>
      </template>

      <template #table-busy>
        <div class="hp-p1-body text-center my-2">
          <b-spinner class="align-middle mr-8" />
          <strong>Memuat...</strong>
        </div>
      </template>
    </b-table>
    <b-modal
      id="modal-delete-confirm"
      hide-footer
      hide-header
      header-class="align-items-center"
    >
      <p class="hp-p1-body mb-0">
        Apakah anda yakin akan menghapus Mobil Unit Ini ?
      </p>

      <div class="d-flex justify-content-end flex-wrap mt-32">
        <b-button variant="text" @click="$bvModal.hide('modal-delete-confirm')">
          Close
        </b-button>

        <b-button class="ml-16" variant="primary" @click="deleteItem">
          Hapus Mobil Unit
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable,
  BButton,
  BIcon,
  BButtonGroup,
  BBadge,
  BSpinner,
  BModal,
  BToast,
} from "bootstrap-vue";
import BloodvanService from "../../../../../api/managebloodvan/manageBloodvanAPI";

export default {
  name: "TableListBloodvans",
  components: {
    BTable,
    BButton,
    BIcon,
    BButtonGroup,
    BBadge,
    BSpinner,
    BToast,
  },
  props: ["isBusy", "bloodvanData"],
  data() {
    return {
      sortBy: "age",
      sortDesc: false,
      isBusy: false,
      itemToDelete: null,
      bloodvans: null,
      fields: [
        { key: "is_active", sortable: false },
        { key: "id", sortable: true },
        { key: "name", sortable: true },
        { key: "address", sortable: true },
        { key: "contact", sortable: true },
        { key: "aksi", sortable: false },
      ],
      items: [],
    };
  },
  methods: {
    deleteMobilUnit(id) {
      this.itemToDelete = id;
      this.$bvModal.show("modal-delete-confirm");
    },

    deleteItem() {
      if (this.itemToDelete) {
        this.$bvModal.hide("modal-delete-confirm");

        BloodvanService.deleteBloodvan(this.itemToDelete)
          .then(() => {
            this.$emit("refresh-table");
            this.$bvToast.toast("Mobil unit berhasil dihapus", {
              title: "Sukses",
              variant: "success",
              solid: true,
            });
          })
          .catch((err) => console.error(err))
          .finally(() => {
            this.itemToDelete = null;
          });
      } else {
        this.$bvModal.hide("modal-delete-confirm");
        this.$bvToast.toast("Penghapusan dibatalkan", {
          title: "Info",
          variant: "info",
          solid: true,
        });
      }
    },
  },
};
</script>
