<template>
  <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0">
    <b-col cols="12" class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden">
      <h2>Kelola Mobil Unit</h2>
      <p class="hp-p1-body mb-2 text-black">Semua ({{ table.total }})</p>
      <div class="header d-flex justify-content-between">
        <router-link to="/dashboards/add-bloodvan">
          <b-button class="add-btn" variant="primary-2">
            Tambah Mobil Unit
          </b-button>
        </router-link>
        <div class="row no-gutters w-15">
          <b-input-group>
            <b-input-group-prepend>
              <b-button
                variant="outline-black-50"
                class="border-right-0 pr-0 pl-2"
              >
                <i class="ri-search-line remix-icon text-primary" />
              </b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="table.name"
              type="search"
              placeholder="Search"
              class="border-left-0"
              @input="debounceSearch"
            />
          </b-input-group>
        </div>
      </div>
    </b-col>
    <TableListBloodvans
      :bloodvan-data="table.items"
      :is-busy="isBusy"
      @refresh-table="getAllBloodvan"
    />
    <PaginateBloodvans
      :current-page="table.currentPage"
      :per-page="table.perPage"
      :total_rows="table.total"
      :on-page-changed="onPageChange"
    />
  </b-row>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  // BIcon,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
} from "bootstrap-vue";
import PaginateBloodvans from "./components/PaginateBloodvans";
import TableListBloodvans from "./components/TableListBloodvans";
import BloodvanService from "../../../../api/managebloodvan/manageBloodvanAPI";
import tableDataMixins from "../../../../mixins/tableDataMixins";

export default {
  name: "Bloodvans",
  mixins: [tableDataMixins],
  data() {
    return {
      isBusy: false,
      items: [],
      searchTitile: "",
      debounceTime: null,
    };
  },
  methods: {
    async getAllBloodvan() {
      this.isBusy = true;
      try {
        const queryParams = {
          page: this.table.currentPage,
          per_page: this.table.perPage,
          search: this.table.name,
        };

        const response = await BloodvanService.getAll(queryParams);

        this.table.items = response.data.data.data;
        this.table.total = response.data.data.total;
      } catch (err) {
        console.log(err);
      }

      this.isBusy = false;
    },
    onPageChange(newPage) {
      this.table.currentPage = newPage;
      this.getAllBloodvan();
    },
    debounceSearch() {
      clearTimeout(this.table.debounceTimer);

      this.table.debounceSearch = setTimeout(() => {
        this.onChangeSearchTable();
      }, 500);
    },
    onChangeSearchTable() {
      this.getAllBloodvan();
    },
  },
  components: {
    BRow,
    BCol,
    BButton,
    // BIcon,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    TableListBloodvans,
    PaginateBloodvans,
  },
  created() {
    this.getAllBloodvan();
  },
};
</script>

<style scoped>
@media screen and (max-width: 576px) {
  .header {
    flex-direction: column;
    gap: 0.5rem;
  }

  .add-btn {
    float: right;
  }
}
</style>
